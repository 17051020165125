/*
 * @Author: 月魂
 * @Date: 2022-08-17 10:08:59
 * @LastEditTime: 2022-10-10 13:40:37
 * @LastEditors: 月魂
 * @Description: 语言配置文件
 * @FilePath: \bingmayong\src\locales\langs\index.js
 */
import en from './en'
import zh from './zh'
import fr from './fr'

export default {
  en,
  zh,
  fr
}
