/*
 * @Author: 月魂
 * @Date: 2022-08-17 09:23:59
 * @LastEditTime: 2022-10-12 16:46:44
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \bingmayong\src\router\index.js
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import settingZh from '../../public/json/setting-zh.json'
import settingFr from '../../public/json/setting-fr.json'
import prefaceZh from '../../public/json/preface-zh.json'
import prefaceFr from '../../public/json/preface-fr.json'
import historyZh from '../../public/json/history-zh.json'
import historyFr from '../../public/json/history-fr.json'
import warriorsZh from '../../public/json/warriors-zh.json'
import warriorsFr from '../../public/json/warriors-fr.json'
import ancientZh from '../../public/json/ancient-zh.json'
import ancientFr from '../../public/json/ancient-fr.json'
import cultureZh from '../../public/json/culture-zh.json'
import cultureFr from '../../public/json/culture-fr.json'
import visitZh from '../../public/json/visit-zh.json'
import visitFr from '../../public/json/visit-fr.json'
import creationZh from '../../public/json/creation-zh.json'
import creationFr from '../../public/json/creation-fr.json'
import i18n from '../locales/i18n'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import ('../views/Index.vue')
  },
  {
    path: '/preface',
    name: 'Preface',
    component: () => import('../views/Preface.vue')
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('../views/History.vue')
  },
  {
    path: '/warriors',
    name: 'Warriors',
    component: () => import('../views/Warriors.vue')
  },
  {
    path: '/ancient',
    name: 'Ancient',
    component: () => import('../views/Ancient.vue')
  },
  {
    path: '/culture',
    name: 'Culture',
    component: () => import('../views/Culture.vue')
  },
  {
    path: '/visit',
    name: 'Visit',
    component: () => import('../views/Visit.vue')
  },
  {
    path: '/creation',
    name: 'Creation',
    component: () => import('../views/Creation.vue')
  },
  {
    path: '/game',
    name: 'Game',
    component: () => import('../views/Game.vue')
  },
  {
    path: '/puzzle',
    name: 'Puzzle',
    component: () => import('../views/Game/Puzzle.vue')
  },
  {
    path: '/face',
    name: 'Face',
    component: () => import('../views/Game/Face.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  if (i18n.global.te('preface')) {
    next()
  } else {
		i18n.global.mergeLocaleMessage('zh', settingZh)
    i18n.global.mergeLocaleMessage('fr', settingFr)
    i18n.global.mergeLocaleMessage('zh', prefaceZh)
    i18n.global.mergeLocaleMessage('fr', prefaceFr)
    i18n.global.mergeLocaleMessage('zh', historyZh)
    i18n.global.mergeLocaleMessage('fr', historyFr)
    i18n.global.mergeLocaleMessage('zh', warriorsZh)
    i18n.global.mergeLocaleMessage('fr', warriorsFr)
    i18n.global.mergeLocaleMessage('zh', ancientZh)
    i18n.global.mergeLocaleMessage('fr', ancientFr)
    i18n.global.mergeLocaleMessage('zh', cultureZh)
    i18n.global.mergeLocaleMessage('fr', cultureFr)
    i18n.global.mergeLocaleMessage('zh', visitZh)
    i18n.global.mergeLocaleMessage('fr', visitFr)
    i18n.global.mergeLocaleMessage('zh', creationZh)
		i18n.global.mergeLocaleMessage('fr', creationFr)
    next()
  }
})

export default router
