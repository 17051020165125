/*
 * @Author: 月魂
 * @Date: 2022-08-17 09:56:52
 * @LastEditTime: 2022-11-25 20:59:33
 * @LastEditors: 月魂
 * @Description: i18n配置文件
 * @FilePath: \bingmayong\src\locales\i18n.js
 */
import { createI18n } from "vue-i18n";
import messages from './langs'

const i18n = createI18n({
  locale: 'fr',
  messages
})

export default i18n
