/*
 * @Author: 月魂
 * @Date: 2022-08-17 10:14:53
 * @LastEditTime: 2022-10-11 16:40:25
 * @LastEditors: 月魂
 * @Description: zh
 * @FilePath: \bingmayong\src\locales\langs\zh.js
 */
const zh = {
  link: {
    preface: '前言',
    history: '历史绵迭',
    warriors: '览古观今',
    ancient: '古法艺作',
    culture: '中西匠心',
    visit: '途秦欲旅',
    creation: '访古出新',
    game: '奇思律动',
  },
  game: {
    title: 'Some interesting games',
    item: "Interesting New Year's pictures - Keeper of the threshold",
  },
  footer: {
    left: {
      title: 'Организатор:',
      item1: 'Амбасада Народне Републике Кине у Републици Србији',
      item2: 'Одељење за културу и туризам провинције Шанси',
    },
    right: {
      title: 'Спонзор:',
      item: 'Компанија за међународни културни развој Шанси Венји'
    },
  }
}

export default zh