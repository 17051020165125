/*
 * @Author: 月魂
 * @Date: 2022-10-08 14:48:50
 * @LastEditTime: 2022-10-11 16:49:29
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \bingmayong\src\locales\langs\fr.js
 */
export default {
  link: {
    preface: 'Оршил',
    history: 'Түүхийн улбаа',
    warriors: `Эрт өдгөөг харах `,
    ancient: 'Эртний урлагийн арга',
    culture: 'Хятад ба барууны ур дарханы мэргэн ухаан',
    visit: 'Чинь гүрний түүх рүү чиглэсэн аялал',
    creation: 'Эртнийх сурвалжилж шиний бүтээх',
    game: 'Rythme original et merveilleux',
  },
  footer: {
    left: {
      title: 'Зохион байгуулагч:',
      item1: 'Улаанбаатар дахь Хятадын соёлын төв',
      item2: 'Шааньши мужийн соёл, аялал жуулчлалын газар',
    },
    right: {
      title: 'Спонзор:',
      item: 'Компанија за међународни културни развој Шанси Венји'
    },
  }
}
