/*
 * @Author: 月魂
 * @Date: 2022-08-17 10:15:01
 * @LastEditTime: 2022-10-08 15:55:21
 * @LastEditors: 月魂
 * @Description: en
 * @FilePath: \bingmayong\src\locales\langs\en.js
 */
const en = {
  link: {
    preface: 'Preface',
    history: 'The Ebbs and Flows of History',
    warriors: 'The Past and the Present of Terracotta Warriors',
    ancient: 'Ancient Workmanship',
    culture: 'Originality from Chinese and Western Cultures',
    visit: 'Visiting Shaanxi',
    creation: 'The Creation of Terracotta Warriors Images',
    game: 'Unusual and Wonderful Rhythm',
  },
  preface: {
    des: [
      'Chinese civilization enjoys a time-honored history. In the long history of more than two thousand years, the local Qin people explored wisdom, reformed and created innovation, they have created series of advanced systems such as character-writing, law, residential registration, transportation, water irrigation works, administration, ranking of titles, metal smelting, weights and measures and so on, all of these have condensed the spiritual treasures of vigorousness, courage, heroism, and pragmatism, also engraved the powerful and splendid epoch of the Great Qin Empire. Time flies, as the sea changed into land and fields, while in the land of Guanzhong — land of Qin people, from the north to the south, Qin people’s descendants are continuing to inherit, create, explore and forging ahead.',
      `The charm of the Terracotta Warriors and Horses of Qin Dynasty has traveled through time and space, it has been known as the "Eighth Wonder of the World". On the occasion of China's "Cultural and Natural Heritage Day",China Cultural Centre In Sydney and the Shaanxi Provincial Department of Culture and Tourism co-hosted this online Modern Art Exhibition: Qin - the Past and Present of Terracotta Warriors, with history, heritage, art and cultural creation as content-ground, taking “Internet +” as the technology carrier, this wonderful cloud exhibition will bring all historical-artifacts back to life. This online exhibition will splendidly present the unique cultural connotation and aesthetic charm of the World Cultural Heritage Qin Terracotta Warriors. It demonstrate the derivative art creations of Qin Terracotta Warriors, like an amazingly painted scroll to interpret the history and civilization of Qin and Han Dynasties, as well as the cultural marks they left in modern China.`,
    ]
  },
  history: {
    part1: {
      des1: 'In the mysterious and vast universe, the time and space are as changeful as stars. A silhouette reflects a grand plan of a great king; the recitation of the protagonist is hidden in fluctuant illusions. Under the dome of Huaxia (an ancient name of China), the Qin Dynasty stirred the destiny of history and carved the laws and norms for the future of China. With thousands of years elapsing, the Dynasty, and its monarch, magnificent structures, and army have remained hidden in the bushes and gullies.',
      des2: 'With the passage of time, an invincible army has sprung up before us, and its story about great achievements of a great King has been unveiled; these historical remains shocked and dazzled the world. The terracotta warriors and horses stand eerily and calmly. The secrets will be unlocked one by one.',
    },
    part2: {
      title: 'The Past',
      des1: 'In 378 BC, the King Qin Xiangong abolished the human sacrifice system in the state of Qin, which had prevailed since Xia and Shang Dynasties and replaced by humanoid figurines made from other materials like pottery, wood, and stone. The terracotta warriors and horses emerged at the right moment under this funeral system. During the construction and the reign of the Emperor Qin Shihuang, the terracotta warriors and horses were made by highly skilled potters at the bottom of society.',
      des2: 'The manufacturing process of terracotta warriors is shown as follows. First, pottery models are burnt with yellow mud, and filled with loess to make fetal shapes, next covered with fine silt, painted in bright colors and then burned in the kiln. Most figurines are spliced, while some are fired first and then spliced, with constant fire temperatures, pure luster, high hardness and gorgeous color. The key colors include soft green, scarlet, purplish red, pink, pinkish purple, sky blue, white, and ocher.',
      des3: 'In March 1974, the mysterious veil of terracotta warriors was unveiled by a farmer in Lintong District, Xi’an City, Shaanxi Province. So far, 4 pits have been found. Pit 1, Pit 2 and Pit 3 are neatly arranged with terracotta warrior arrays, but Pit 4 only has the grave mound without warriors. More than 8,000 terracotta warriors were unearthed in the three pits.',
      des4: 'The terracotta warriors fall into two broad categories: soldiers and military officers. According to their postures, the terracotta warriors also can be divided into chariot soldiers, standing shooters, kneeling shooters, knight soldiers, military commanders, cavalry, horsemen, carriages and horses, and pummeled horses. In December 1987, Emperor Qin Shihuang’s Mausoleum and Terracotta Warriors and Horses were on the World Heritage List. Since then, the Terra Cotta Warriors have become world-famous cultural elements of Shaanxi Province and a must-see cultural landmark for foreign leaders and tourists who are visiting China.',
      des5: ''
    },
    part3: {
      title: 'Archaeological Discovery',
      des1: 'The terracotta warrior pit, 1.5 km east of the Emperor Qin Shihuang’s Mausoleum in Lintong District, Xi’an City, Shaanxi Province, was excavated in full scale for 3 times from March, 1974 to June, 2009. The funerary pit of Emperor Qin Shihuang’s Terracotta Warriors faces the east. The three pits are arranged in a zigzagging pattern. The terracotta warrior pits are underground tunnel-type civil engineering structures. The large pit with a depth of about 5 m is dug from the ground, and parallel soil walls are built in the pit.',
      des2: 'The two sides of the wall are arrayed with wooden columns, on which the cross beams are placed. The crossbeams and the soil walls are covered with sheds, on which a layer of reed mat and soil is paved successively, to form the roof of the pit, which is 2 m high above the ground surface. The bottom of the pit is paved with black bricks. The inner height from the top to bottom of the pit is 3.2m. After the pottery warriors and horses were placed into the pit, all the doorways of the pit were blocked with standing columns, and filled with rammed earth, to form an enclosed underground structure.'
    },
    // 第四部分
    part4: {
      title: 'The Images of Terracotta Warriors',
      des: 'The terracotta warriors mainly fall into soldiers and military officers according to their identities. The military officers can be classified into low, intermediate and high levels. In general, the ordinary soldiers do not wear crowns, while military officers do. The crowns of ordinary military officials are different from those of the generals, so are their armors. The terracotta warriors include infantry, cavalry, and chariot soldiers. According to their postures, the terracotta warriors also can be divided into chariot soldiers, standing shooters, kneeling shooters, knight soldiers, military commanders, cavalry, horsemen, carriages and horses, and pummeled horses.',
    },
    photo: 'More Photos',
  },
  warriors: {
    part1: {
      des: 'The terracotta warrior’s relic, built 2,500 years ago, has been known as the eighth wonder of the world since it was excavated, and was also included on the World Heritage List, and has become an immortal historical and cultural treasure. At present, through the display scenes integrating modern technologies and innovative techniques, the charm of Terracotta Warriors was shown to the public by means of the Internet Plus the current conditions and status are vividly depicted through a panorama of VR Terracotta Warriors Pavilion, Flash Animation of Rhapsody of Terracotta Warriors, and series documentary films of Hello, Mr. Bing.',
    },
    part2: {
      title: 'VR PANORAMA',
      des: 'welcome to VR Panorama',
      cont: 'The digital museum can give an all-round display of Pit 1, Pit 2 and Pit3 and bronze carriage and horse exhibition hall, with the online VR panoramic technology as a carrier to tell the story with the overall pixels reaching 50 billion, you don’t need to go out and enjoy the panoramic perspective of spectacular view of 8,000 terracotta warriors through the computer.',
    },
    partLeft: {
      title: '[Digital museum]',
      des: '1 panorama, Panorama display of Pit 3 and Chinese and English identifiers',
    },
    partRight: {
      title: '[Digital museum]',
      des: '1 panorama, Panorama display of bronze carriage and horse exhibition hall and Chinese and English identifiers',
    },
    part4: {
      title: 'RHAPSODY OF TERRACOTTA WARRIORS',
      des: 'welcome to Rhapsody of Terracotta Warriors',
      cont: 'The Rhapsody of terracotta warriors takes three warriors was the prototype and Flash animation as carrier to tell the story about the terracotta warriors in witty and humorous words. Sometimes, many unexpected dialogue scenes may appear, refreshing people’s eyes.',
    },
  },
  ancient: {
    part1: {
      title: 'Ancient Workmanship',
      des: 'welcome to Ancient Workmanship',
      cont1: 'As historical treasures of world culture, the terra cotta warriors can show us their important values from many perspectives. From an artistic point of view, various vigorous postures of terracotta warriors can be regarded as an inspiration source for artistic creation......',
      cont2: 'As historical treasures of world culture, the terra cotta warriors can show us their important values from many perspectives. From an artistic point of view, various vigorous postures of terracotta warriors can be regarded as an inspiration source for artistic creation......',
    },
    part2: {
      title: 'INTRODUCTION TO MODERN DANCE SYMBIOSIS',
      des: 'welcome to Introduction to Modern Dance Symbiosis',
      cont: 'This work takes as a theme, and expounds similarities among different cultures and education and habits. These similarities or commonalities are symbiosis. The seemingly same things actually have their own personalities and charms, while the seemingly different things also have inextricable similarities and connections... The modern dance Symbiosis absorbs both terracotta element and Arab style as main inspiration source for creation. In the 60-minute dance performance, the dancers imitate the ideology and spiritual appearance of various people with their physical movements and exaggerated facial expressions, and describe things of daily life and historical significance in artistic language. In other words, “Art comes from life but is higher than life.',
    },
    part3: {
      title: 'MODERN DANCE SYMBIOSIS',
      des: 'welcome to Modern Dance Symbiosis',
    },
    part4: {
      title: 'SOUL OF THE TERRA COTTA WARRIORS',
      des: 'Electronic presentation of original script of Qinqiang',
      cont: 'Qinqiang Dance Drama Soul of the Terracotta Warriors is large-scale artistic stage play of Qingqiang written by the famous dramatist Ji Fuji of Shaanxi Province, based on discovery of the Terra-cotta Warriors in 1983, combined with Qinqiang opera, one of the eight major dramas in China, and uses the interlude techniques. In 1984, the Qinqiang Dance Drama Soul of the Terracotta Warriors joined in the first Xi’an Drama Festival and was awarded with the third prize. In the same year, it also won the Commemorative Award of Excellent Works for Dance Creation issued by Shaanxi Branch of Chinese Dancers Association.',
    },
    part5: {
      title: 'ONLINE SHOW OF A TERRACOTTA WARRIOR',
      des: 'Online Show of A Terracotta Warrior',
      cont: 'A Terracotta Warrior is a fantasy romance film adapted from Hong Kong Authoress Lilian Lee’s novel Qin Terracotta, directed by Cheng Xiaodong and performed by Gong Li, Zhang Yimou and Ringo Yu. Set in the Qin Dynasty, the Republic of China and the 1970s, the film tells the love story of Meng Tianfang, an official in the Qin Dynasty, and the maid Han Dong’er.',
      cont2: '',
    },
  },
  culture: {
    part1: {
      title: 'ORIGINALITY FROM CHINESE AND WESTERN CULTURES',
      des: 'Welcome to Originality from Chinese and Western Cultures',
      cont: 'As a unique form of expression, art is originated from nature and living materials, and exists in the avenue of changed and unchanged. Whether in the East or the West, the barriers among different cultures affect the style of artistic creation; however, in general, the art forms seek common grounds and reserve differences. Each has its own advantages. The integrated innovation of terracotta warriors and Lego bricks can be seen as a masterpiece for the communication of Chinese and Western cultures. Tens of thousands of Lego bricks have been piled up to form a solemn image of terracotta warriors, affording us a magnificent view.',
    },
    part2: {
      title: 'LEGO TERRACOTTA WARRIORS',
      des: 'welcome to Rhapsody of Terracotta Warriors',
      cont: 'The Rhapsody of terracotta warriors takes three warriors was the prototype and Flash animation as carrier to tell the story about the terracotta warriors in witty and humorous words. Sometimes, many unexpected dialogue scenes may appear, refreshing people’s eyes.',
    },
  },
  visit: {
    part1: {
      title: 'VISITING SHAANXI',
      des: 'WELCOME TO VISITING SHAANXI',
      cont1: 'Shaanxi, geographically situated in the center of China, is one of the birthplaces of Chinese civilization and holds an important position in Chinese history and culture. A proverb goes like this, “From Shenzhen, you may know ten-year history of China,; from Shanghai, you may know a hundred years’ history of China; from Beijing, you may know five hundred years’ history of China, and from Shaanxi, you may know five thousand years’ history of China”.',
      cont2: 'The Qin Teenager is a cultural tourism animation authorized by Emperor Qin Shihuang’s Mausoleum Site Museum and takes the image of the knight warrior of Qin terracotta warriors as its prototype. It is made by means of 3D modeling. Combined with travel, food, culture and other cultural elements, the Qin teenager presents us a cute and adorable image and gives us various performances.',
    },
    part2: {
      title: 'Rebirth of the Terracotta Warrior',
    },
    part3: {
      title: 'HELLO, MR. BING',
      des: 'Hello, Mr. Bing',
      cont: 'In video series Mr. Bing, a tour guide cosplays as Qin Terracotta Warriors and guides foreign female tourists to visit scenic spots in Shaanxi, tastes Shaanxi cuisine, and record Shaanxi’s famous tourist attractions in travel notes.'
    },
  },
  creation: {
    part1: {
      title: 'The Creation of Terracotta Warriors Images',
      cont1: 'China possesses vast expanses of land and diversified cultures, resulting in huge differences in diet, farming, folk customs, and festivals and other cultural elements. The cultural continuation and innovative methods vary from region to region. In all ages, many forms of wisdom have been preserved. The intangible cultural heritage has become an important channel for cultural transmission and continuation.',
      cont2: 'The wisdom of modern people is expressed through new images to convey the culture breadth. On the one hand, it learns from the wisdom of the ancient people; on the other hand, it integrates technological innovation so as to make new changes of culture and creativity. In fact, whether it is cultural creation or intangible cultural heritage, only if it can express the cultural charm of China, can it deserve praises.',
    },
    part2: {
      title: 'Display of ICH Terracotta Warriors Lantern Show',
      cont1: 'Chinese colored lantern has been popular in both ancient and modern ages; ICH lantern show has gained a great reputation for a long time.These words are not groundless rumors. The hardworking Chinese people inherited and developed the lanterns and have assembled a lantern culture with their wisdom, so that the art of lanterns stand out in this great land.',
      cont2: 'The creation inspiration for lantern figurines originated from the shape of the Terracotta Warriors, which is famous known to the people all over the world. It also combines the traditional hand-made skills of Sichuan Zigong Lantern, a national intangible cultural heritage project in China. The current models are finally formed after a few tests and modifications. The lantern figurines have an average height of 2.2 meters and fall into general, horseman and armor figurines, especially female figurines, pregnant women, and children figurines, in order to change the cold and solemn images the terracotta warriors and intensify the gentle humanistic atmosphere.',
      title1: 'Derivative Cultural Creation of Terracotta Warriors',
      cont3: 'The cultural and creative products about terracotta warriors are classified into doll categories (Qin Teenager, Qin baby, terracotta warrior blocks), life categories(Qin-styled key chains, tiles with Qin cloud pattern, Qin-styled fans and note paper); souvenir categories(terracotta-warrior currencies, prototypes of terracotta warriors). A total of 10 authorized series, with unique originality and fresh conception, are extremely desirable to the public.',
    }
  },
  game: {
    title: 'Some interesting games',
    item: "Interesting New Year's pictures - Keeper of the threshold",
  },
  footer: {
    left: {
      title: 'Организатор:',
      item1: 'Амбасада Народне Републике Кине у Републици Србији',
      item2: 'Одељење за културу и туризам провинције Шанси',
    },
    right: {
      title: 'Спонзор:',
      item: 'Компанија за међународни културни развој Шанси Венји'
    },
  }
}

export default en