/*
 * @Author: 月魂
 * @Date: 2022-08-17 09:23:59
 * @LastEditTime: 2022-10-09 14:48:50
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \bingmayong\src\main.js
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './locales/i18n'
import { ElCollapseTransition } from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App)
app.component(ElCollapseTransition.name, ElCollapseTransition)
app.use(router).use(i18n)

app.mount('#app')
