import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted } from 'vue';
export default {
  __name: 'App',

  setup(__props) {
    onMounted(() => {
      if (_isMobile()) {
        const t = new Date().getTime();
        window.location.replace(`https://wlbtbmy.wearetogether.com.cn/mobile/index.html#/?t=${t}`); // window.location.replace(`http://172.20.0.39:8000/mobile/index.html#/?t=${t}`)
      }
    }); // 判断移动端还是pc端

    function _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    }

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createBlock(_component_router_view);
    };
  }

};